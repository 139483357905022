import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Property Deed",
      content: (
        <>
          <p>
            A property deed is a legal document that proves who owns the
            property. When there’s a transfer of ownership, the deed is the
            document used to officially complete the transaction.
          </p>
        </>
      ),
    },
    {
      title: "Property",
      content: (
        <>
          <p>
            In legal terms, property refers to land (including unimproved land)
            and anything that’s permanently attached to it, such as a home or
            other structures.
          </p>
        </>
      ),
    },
    {
      title: "Title",
      content: (
        <>
          <p>
            Refers to someone’s legal ownership of property. For example, a
            property owner may be referred to as “holding title” to the
            property, meaning they own it and can legally use it as they wish.
            Although title is suppported by a deed, the term itself refers to
            ownership and not a physical document.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Property Transfer Attorney San Diego | Brierton, Jones & Jones"
        description="Transferring property by selling or gifting it to someone can have major tax implications. Our estate planning attorneys can advise you on the best course of action. Contact us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                {/* <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  TAX ADVICE
                </div> */}
                <h1>
                  Parent-Child and Grandparent-Grandchild Exclusions San Diego
                </h1>
                <p>
                  Transferring property by selling or gifting it to someone can
                  have major tax implications, especially when it's done between
                  a parent and child or grandparent and grandchild. Our{" "}
                  <a href="https://briertonjones.com/estate-planning-attorney-san-diego/">
                    estate planning attorneys
                  </a>{" "}
                  can advise you on the best course of action for transferring
                  property so you can avoid tax pitfalls.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/10.0 Real Property Tax Consultation/1.0 Hero Desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>How We Help You With Property Transfers</h2>
                <p>
                  Our legal advice is comprehensive and includes even the more
                  personal aspects of property transfers, such as planning for
                  the transferor’s financial needs and the responsibilities of
                  the transferee. We’ll counsel you on the change in ownership
                  rules and applicable exclusions from reassessment.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re considering getting legal help from an attorney,
                  here are some of the common terms to familiarize yourself
                  with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={9} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Meet with one of our San Diego property tax attorneys if you have questions or want more information." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/10.0 Real Property Tax Consultation.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/10.0 Real Property Tax Consultation.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
